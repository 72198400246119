<template>
  <v-data-table
    class="tp-table-scroll tp-table__row-pointer datatable px-3 py-2"
    calculate-widths
    disable-pagination
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :headers="headers"
    hide-default-footer
    :loading="statusRequest.value === 'loading-getListOffBiz'"
    loading-text="Đang tải dữ liệu"
    :items="listOffBiz"
    item-key="id"
    @click:row="viewDetail($event)"
  >
    <template v-slot:[`item.code`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="copied-label"
            v-bind="attrs"
            v-on="on"
            @click.stop="copyToClipboard(item.code)"
          >
            {{ item.code }}
          </div>
        </template>
        <span>Sao chép</span>
      </v-tooltip>
    </template>

    <!--    <template v-slot:[`item.service_supplier`]="{ item }">-->
    <!--      &lt;!&ndash;      <v-tooltip bottom>&ndash;&gt;-->
    <!--      <template v-slot:activator="{ on, attrs }">-->
    <!--        <div-->
    <!--          class="copied-label"-->
    <!--          v-bind="attrs"-->
    <!--          v-on="on"-->
    <!--          @click.stop="copyToClipboard(item.code)"-->
    <!--        >-->
    <!--          {{ item.service_supplier ? item.service_supplier.name : "" }}-->
    <!--        </div>-->
    <!--      </template>-->
    <!--      &lt;!&ndash;        <span>Sao chép</span>&ndash;&gt;-->
    <!--      &lt;!&ndash;      </v-tooltip>&ndash;&gt;-->
    <!--    </template>-->
    <template v-slot:[`item.service_supplier`]="{ item }">
      <div class="my-1">
        <div class="">
          {{ item.service_supplier ? item.service_supplier.name : "" }}
        </div>
      </div>
    </template>
    <template v-slot:[`item.total_fee`]="{ item }">
      <div style="width: 86px">
        <span>{{ item.total_fee | formatCurrency }}</span>
      </div>
    </template>

    <template v-slot:[`item.serial.serial_number`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="copied-label"
            v-bind="attrs"
            v-on="on"
            @click.stop="copyToClipboard(item.serial.serial_number)"
          >
            {{ item.serial.serial_number }}
          </div>
        </template>
        <span>Sao chép</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.serial.product_name`]="{ item }">
      <div class="my-1">
        <div class="">
          {{ item.serial.product_name }}
        </div>
        <div
          class="sub--text text-subtitle-2 font-weight-light grey--text text--darken-2"
          v-html="item.serial.option_name"
        ></div>
      </div>
    </template>

    <template v-slot:[`item.due_date`]="{ item }">
      <div style="min-width: 86px">
        <div
          v-if="isDueDateExpired(item.due_date)"
          class="d-flex flex-col red--text"
        >
          <span>{{ item.due_date }}</span>
          <span>Đã quá hạn</span>
        </div>
        <span v-else>{{ item.due_date }}</span>
      </div>
    </template>

    <template v-slot:[`item.step`]="{ item }">
      <v-chip
        class="font-weight-bold"
        :color="findedStatus(REPAIR_STATUS, item.step).color"
        small
        outlined
      >
        {{ findedStatus(REPAIR_STATUS, item.step).label }}
      </v-chip>
    </template>

    <template v-slot:[`item.selected_action`]="{ item }">
      <v-chip
        class="font-weight-bold"
        :color="findedStatus(ACTION_STATUS, item.selected_action).color"
        small
        outlined
      >
        {{ findedStatus(ACTION_STATUS, item.selected_action).label }}
      </v-chip>
    </template>
  </v-data-table>
</template>

<script>
import dayjs from "dayjs";
import {
  ACTION_STATUS,
  INTERNAL_REPAIR_HEADER,
  REPAIR_STATUS,
  findedStatus
} from "../../../../utils/static-data";

export default {
  data() {
    return {
      headers: INTERNAL_REPAIR_HEADER,
      ACTION_STATUS: ACTION_STATUS,
      REPAIR_STATUS: REPAIR_STATUS,
      stepList: [
        { id: 1, name: "Chờ hướng giải quyết", color: "amber" },
        { id: 2, name: "Chờ xử lý", color: "deep-purple" },
        { id: 3, name: "Đang xử lý", color: "blue" },
        { id: 4, name: "Đã xử lý xong", color: "green" }
      ]
    };
  },
  computed: {
    listOffBiz() {
      return this.$store.getters["INTERNAL_REPAIR_NOTE/listOffBiz"];
    },
    statusRequest() {
      return this.$store.getters["INTERNAL_REPAIR_NOTE/statusRequest"];
    }
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    viewDetail(item) {
      this.$router.push({
        name: "not-sold-warehouse_internal-repair-notes-detail",
        params: {
          offBizId: item.id
        }
      });
    },

    findedStatus(statusList, statusValue) {
      return findedStatus(statusList, statusValue);
    },

    isDueDateExpired(time) {
      const dueDate = dayjs(time, "DD/MM/YYYY").startOf("day");
      const now = dayjs().startOf("day");

      return now.isAfter(dueDate);
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }
  .copied-label {
    cursor: pointer;
    display: inline-block;
  }
  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
  .sub--text {
    ::v-deep p {
      margin-bottom: 0;
    }
  }
}
</style>
