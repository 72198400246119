var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"action mr-5 mb-4"},[_c('div',{staticClass:"action-row d-flex align-center justify-space-between"},[_c('div',{staticClass:"action-row--col flex-grow-1 pr-5"},[_c('tp-search-option',{attrs:{"options":_vm.searchOptions,"value":_vm.searchKey},on:{"search":function($event){return _vm.updateSearchKey($event)}}})],1),_c('v-btn',{staticClass:"mr-3 rounded-lg",attrs:{"color":"primary","dark":"","depressed":"","loading":_vm.statusRequest.value === 'loading-exportReportFile',"outlined":""},on:{"click":function($event){return _vm.$emit('exportReportFile')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-download-outline")]),_vm._v(" Xuất file ")],1),_c('div',{staticClass:"action-row--col"},[_c('v-btn',{staticClass:"rounded-lg mr-3",attrs:{"color":"primary","depressed":""},on:{"click":_vm.gotoDetailPage}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Thêm mới ")],1)],1)],1),_c('div',{staticClass:"action-row d-flex align-center justify-space-between mt-4 gap-10 overflow-x-auto"},[_c('div',{staticClass:"action-row--col align-center"},[_c('div',{staticClass:"action-row d-flex align-center justify-space-between gap-2"},[_vm._l((_vm.filterDueDateTemp),function(item){return [(item.count !== 0)?_c('v-btn',{key:item.value,staticClass:"font-weight-regular text-body-1 text-none",attrs:{"color":item.active ? 'primary' : 'white',"elevation":"0","height":"40"},on:{"click":function($event){return _vm.selectDueDateFilter(item)}}},[_vm._v(" "+_vm._s(item.label)+" "),_c('v-badge',{attrs:{"color":item.active
                  ? 'white'
                  : item.value === 0
                  ? 'red darken-1'
                  : 'grey lighten-3',"inline":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',{class:item.active
                      ? 'primary--text'
                      : item.value === 0
                      ? 'text-white'
                      : 'text-black'},[_vm._v(" "+_vm._s(item.count)+" ")])]},proxy:true}],null,true)})],1):_vm._e()]})],2)]),_c('div',{staticClass:"action-row--col d-flex align-center"},[_c('tp-btn-overflow',{attrs:{"btn-class":"white rounded-lg mr-5 flex-shrink-0","items":_vm.showFilterList,"selected-value":_vm.selectedShowFilterId},on:{"change":function($event){_vm.selectedShowFilterIndex = $event},"select":function($event){return _vm.selectShowFilter($event)}}}),_c('div',{staticClass:"text-body-2 flex-shrink-0"},[_vm._v(" "+_vm._s(_vm.paginationInfo.from)+" - "+_vm._s(_vm.paginationInfo.to)+" trong số "+_vm._s(_vm.paginationInfo.itemTotal)+" ")]),_c('v-pagination',{attrs:{"color":"primary","length":_vm.paginationInfo.pageTotal,"total-visible":5},model:{value:(_vm.curPage),callback:function ($$v) {_vm.curPage=$$v},expression:"curPage"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }